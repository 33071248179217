@import url(https://fonts.googleapis.com/css?family=Courgette);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: left;
  font-family: Courgette;
}

.App-bg {
  width: 100%;
}

.Real-bg {
  width: 100%;
  transition: -webkit-filter 1s;
  transition: filter 1s;
  transition: filter 1s, -webkit-filter 1s;
}

.tomas {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main-header {
  font-size: 16px;
  font-weight: 200;
}

.main-holder {
  position: relative;
}

.main-holder .App-bg {
  vertical-align: top;
  width: 100%; /* max width */
  opacity: 0;  /* make it transparent */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.holder {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.full {
  cursor: pointer;
  z-index: 2;
}

.full img {
  max-width: 35%;
  max-height: 40%;
  position: fixed;
}

.imgClose {
  position: absolute;
  top: 0;
  right: 0;
  background: grey;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 25px;
  transition: opacity 1s;
  -webkit-animation: fadeoutClose 1s;
          animation: fadeoutClose 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -moz-animation: fadeoutClose 1s;
}

.full:hover .imgClose {
  -webkit-animation: fadeinClose;
          animation: fadeinClose;
}

@-webkit-keyframes fadeoutClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeoutClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeinClose {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeinClose {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.thumb {
  position: absolute;
  cursor: pointer;
  z-index: 1;
}

.close {
  background: white;
  color: darkslategrey;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  font-weight: bolder;
}

.text-container {
  position: absolute;
  top: 0;
  left: 40%;
  width: 60%;
  height: 100%;
  max-height: 100vh;
}

.text-holder {
  position: absolute;
  bottom: 10px;
}

.text-holder p {
  text-align: left;
  font-size: 16px;
}

.text-holder a {
  text-decoration: none;
  color: white;
  margin-right: 20px;
}

.mobile-holder {
  display: none;
  max-width: 100%;
  width: 100%;
}

@media all and (max-width: 768px) { 
  .mobile-holder {
    display: block;
  }
  .mobile-holder img {
    max-width: 100%;
  }
  .full {
    display: none;
  }
  .holder {
    display: none;
  }
  .text-holder a {
    display: block;
    margin-top: 10px;
  }
}
